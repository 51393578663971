




































































import AuthLayout from '@/views/AuthViews/AuthLayout.vue';
import PasswordInputExtended from '@/components/PasswordInputExtended.vue';
import { restorePassword } from '@/api/Auth';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { NavigationGuardNext, Route } from 'vue-router';
import { required } from '@/helpers/validation';
import { token } from '@/helpers/authHelpers';

export default {
  name: 'PasswordRecoveryView',
  data(): any {
    return {
      showOldPassword: false,
      showNewPassword: false,
      formValid: true,
      passwordReset: false,
      passwordFirst: '',
      passwordSecond: '',
      secondPasswordRules: [
        (v) => required(v, 'New Password'),
        (v) => v === this.passwordFirst || 'Must be the same as first one'
      ]
    };
  },
  components: {
    AuthLayout,
    PasswordInputExtended
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    if (!token) {
      next('login');
    } else {
      next();
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formValid) {
          restorePassword(this.passwordFirst, token)
            .then(() => {
              this.passwordReset = true;
            })
            .catch((e) => errorToastMessage(e.message || e));
        }
      });
    }
  }
};
